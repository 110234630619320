import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import {FirebaseService} from './firebase.service';
import {FirebaseLoginService} from './firebase-login/firebase-login.service';
import {FirebaseEquipmentService} from './firebase-equipment/firebase-equipment.service';

@NgModule({
  declarations: [
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
  ],
  providers: [FirebaseService, FirebaseLoginService, FirebaseEquipmentService],
  exports: []

})
export class FirebaseModule { }
