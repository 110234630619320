<h2 *ngIf="loanee">Loans for {{ loanee.displayName }}</h2>
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="returnButton">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:100px;">Return</th>
    <td mat-cell *matCellDef="let element" style="width:100px;"><button mat-flat-button color="primary" (click)="markReturned(element)">Return</button></td>
  </ng-container>
  <ng-container matColumnDef="dateLoaned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:160px;">Loan Date</th>
    <td mat-cell *matCellDef="let element" style="width:160px;">{{ (element.loanedDate == 'Invalid Date') ? '' : (element.loanedDate.seconds * 1000 | date) }}</td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:260px;">Serial/Reference</th>
    <td mat-cell *matCellDef="let element" style="width:260px;">{{ element.reference }}</td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:160px;">Description</th>
    <td mat-cell *matCellDef="let element" style="width:160px;">{{ element.description }}</td>
  </ng-container>
  <ng-container matColumnDef="signedBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:60px;">Signed By</th>
    <td mat-cell *matCellDef="let element" style="width:60px;">{{ element.signedBy }}</td>
  </ng-container>
  <ng-container matColumnDef="loanStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:60px;">Status</th>
    <td mat-cell *matCellDef="let element" style="width:60px;">{{ element.loanStatus }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="6">No data matching the filter {{input.value}}</td>
  </tr>
</table>
<p>
  <button mat-menu-item (click)="goToEmployees()">
    <mat-icon>arrow_back</mat-icon>
    <span>Back to Employee List</span>
  </button>
</p>
<button mat-fab color="primary" class="mat-fab" aria-label="Add Loan" (click)="addDialog()">
  <mat-icon>add</mat-icon>
</button>
