import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {FirebaseLoginService} from './firebase/firebase-login/firebase-login.service';
import {AzureService} from './azure/azure.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public authService: FirebaseLoginService,
    public azureService: AzureService,
    private router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().subscribe(user => {
        if (!user) {
          resolve(false);
        }
        this.authService.getCurrentToken().subscribe(token => {
          if (!token) {
            resolve(false);
          }
          this.azureService.getGroupMembers(token, 'ed028f1f-168b-4d4d-8344-5d4e6d7d5b6e').then(management => {
            this.azureService.getMe(token).then(me => {
              const manager = (management.find(m => m.id === me.id));
              if (manager) {
                resolve(true);
              } else {
                resolve(false);
              }
            });
          });
        });
      });
    });
  }
}
