import {Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {FirebaseLoginService} from './firebase/firebase-login/firebase-login.service';
import {AzureService} from './azure/azure.service';
import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {AzureUser} from './azure/AzureUser';
import {of} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'acutec-asset-register';
  isLoggedIn = false;
  picture: SafeUrl = '';
  pictureStyle: SafeStyle = '';
  me = new AzureUser();
  isManager = false;

  constructor(private firebaseLogin: FirebaseLoginService, private azureService: AzureService, private sanitizer: DomSanitizer, private router: Router) {
    // TODO: We need to merge this into a service
    this.firebaseLogin.getCurrentUser().subscribe(user => {
      if (user) {
        console.log('We have a User');
        this.firebaseLogin.getCurrentToken().subscribe(token => {
          if (token) {
            console.log('token');
            console.log(token);

            this.azureService.getMe(token).then(me => {
              console.log('me');
              console.log(me);
              this.isLoggedIn = true;
              this.azureService.getMePhoto(token).then(photo => {
                console.log('got photo');
                this.me = me;
                const url = window.URL || window.webkitURL;
                this.picture = this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(photo));
                this.pictureStyle = this.sanitizer.bypassSecurityTrustStyle("url("+url.createObjectURL(photo)+")");
                console.log(this.pictureStyle);
                // "Asset Register Management"
                this.azureService.getGroupMembers(token, 'ed028f1f-168b-4d4d-8344-5d4e6d7d5b6e').then(management => {
                  console.log('management');
                  console.log(management);

                  const manager = (management.find(m => m.id === me.id));
                  if (manager) {
                    console.log('manager');
                    console.log(manager);
                    this.isManager = true;
                  }
                });
              });
            }).catch(error => {
              console.log('Token Invalid');
              console.log(error);
            });
          }
        });
      } else {
        this.isLoggedIn = false;
        this.isManager = false;
      }
    });
  }

  ngOnInit(): void {

  }

  logIn(): void {
    this.firebaseLogin.microsoftLogin();
  }

  logOut(): void {
    this.firebaseLogin.logout();
    this.router.navigateByUrl('/');
  }

  goToEmployees(): void {
    this.router.navigateByUrl('/employee');
  }
}
