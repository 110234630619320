import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {EquipmentListComponent} from './equipment-list/equipment-list.component';
import {AuthGuard} from './auth.guard';
import {WelcomeComponent} from './welcome-component/welcome.component';

// TODO: We need another component to show MY LOANS, so we can mark an item as received. This should probably be the default view.
// TODO: Managers (how will we determine this? From AD Group?) will have access to the Employee List. Specific Employees or all?

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
  },
  {
    path: 'employee',
    component: EmployeeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee/:uid',
    component: EquipmentListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
