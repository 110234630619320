import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import {AzureUser} from './AzureUser';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private graphClient: Client;
  token: string;
  constructor() {
    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        done(null, this.token);
      }
    });
  }

  async getUsers(token: string): Promise<AzureUser[]> {
    this.token = token;

    let results: any[] = [];

    try {
      let result =  await this.graphClient
        .api('/users')
        .select('displayName,givenName,mail,department,id')
        .get();

      if (result.value.length > 0) {
        results = results.concat(result.value);
      }

      while (isNotNullOrUndefined(result['@odata.nextLink']) && result.value.length > 0) {
        console.log(result);

        result = await this.graphClient
          .api(result['@odata.nextLink'])
          .get();

        if (result.value.length > 0) {
          results = results.concat(result.value);
        }
      }

      return results;
    } catch (error) {
      throw(error);
    }
  }

  async getUser(token: string, id: string): Promise<AzureUser> {
    this.token = token;
    try {
      const result = await this.graphClient
        .api('/users/' + id)
        .get();
      return result;
    } catch (error) {
      throw(error);
    }
  }

  async getMe(token: string): Promise<AzureUser> {
    this.token = token;
    try {
      const result = await this.graphClient
        .api('/me')
        .get();
      console.log('result');
      console.log(result);

      return result;
    } catch (error) {
      console.log('Error');
      console.log(error);
      throw(error);
    }
  }

  async getMePhoto(token: string): Promise<any> {
    this.token = token;
    try {
      const result = await this.graphClient
        .api('/me/photos/48x48/$value')
        .get();
      return result;
    } catch (error) {
      throw(error);
    }
  }

  async getGroupMembers(token: string, id: string): Promise<AzureUser[]> {
    this.token = token;

    let results: any[] = [];

    try {
      let result =  await this.graphClient
        .api('/groups/' + id + '/transitiveMembers')
        .get();

      if (result.value.length > 0) {
        results = results.concat(result.value);
      }

      while (isNotNullOrUndefined(result['@odata.nextLink']) && result.value.length > 0) {
        result = await this.graphClient
          .api(result['@odata.nextLink'])
          .get();

        if (result.value.length > 0) {
          results = results.concat(result.value);
        }
      }
      console.log('returned members');
      console.log(results);
      return results;
    } catch (error) {
      throw(error);
    }
  }
}
