import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseEquipmentService} from '../firebase/firebase-equipment/firebase-equipment.service';
import {MatTableDataSource} from '@angular/material/table';
import {Equipment} from '../firebase/firebase-equipment/Equipment';
import {IssueEquipmentComponent} from '../issue-equipment/issue-equipment.component';
import {MatDialog} from '@angular/material/dialog';
import {AzureService} from '../azure/azure.service';
import {FirebaseLoginService} from '../firebase/firebase-login/firebase-login.service';
import {AzureUser} from '../azure/AzureUser';
import * as moment from 'moment';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<Equipment>;
  displayedColumns: string[] = ['returnButton', 'dateLoaned', 'reference', 'description', 'signedBy', 'loanStatus'];
  userId: string;
  loanee: AzureUser;
  loaner: AzureUser;

  constructor(private azure: AzureService,
              private login: FirebaseLoginService,
              private equipment: FirebaseEquipmentService,
              public dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      const userId = paramMap.get('uid');
      if (userId === undefined) {
        this.router.navigateByUrl('/');
      } else {
        this.userId = userId;
        this.equipment.GetActiveLoansForEmployee(userId).subscribe(equipment => {
          this.dataSource = new MatTableDataSource(equipment);
          this.dataSource.sort = this.sort;
          console.log(equipment);
        });

        this.login.getCurrentToken().subscribe(result => {
          if (result) {
            this.azure.getUser(result, this.userId).then(user => {
              this.loanee = user;
            });

            this.azure.getMe(result).then(user => {
              this.loaner = user;
            });
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {

  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addDialog(): void {
    const dialogRef = this.dialog.open(IssueEquipmentComponent, {
      data: { userId: this.userId, displayName: this.loanee.displayName,  }
    });

    dialogRef.afterClosed().subscribe((result) => {
      result.equipment.loanStatus = 'Active';
      result.equipment.signedById = this.loanee.id;
      result.equipment.signedBy = this.loanee.displayName;
      result.equipment.loanedToId = this.userId;
      result.equipment.loanedById = this.loaner.id;
      result.equipment.loanedBy = this.loaner.displayName;
      result.equipment.signedDate = moment().toDate();

      this.equipment.AddLoan(result.equipment).then(documentReference => {
        this.equipment.AddPicture(documentReference, result.signature);
      });
    });
  }

  signForDialog(): void {
    // TODO: Intended Purpose - Allow End User to Sign For Equipment. Store User Id and Date.
  }

  editDialog(): void {
    // Show minimal information
    // TODO: Intended Purpose - To allow Managers to Confirm the item has been returned, lost, damaged, stolen etc.
  }

  markReturned(equipment: Equipment): void {
    console.log(equipment);
    equipment.dateReturned = moment().toDate();
    equipment.returnedById = this.loanee.id;
    equipment.returnedBy = this.loanee.displayName;
    equipment.receivedById = this.loaner.id;
    equipment.receivedBy = this.loaner.displayName;
    equipment.loanStatus = 'Returned';

    this.equipment.UpdateLoan(equipment);
  }

  goToEmployees(): void {
    this.router.navigateByUrl('/employee');
  }
}
