import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';


import {AzureService} from '../../azure/azure.service';
import firebase from 'firebase';
import AuthCredential = firebase.auth.AuthCredential;
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class FirebaseLoginService {

  currentUser: Observable<firebase.User>;
  currentCredential: Observable<AuthCredential>;
  redirectResult: Subject<any> = new Subject<any>();
  accessToken: Subject<string> = new BehaviorSubject<string>('');

  constructor(private angularFireAuth: AngularFireAuth, private azureService: AzureService) {
    this.angularFireAuth.authState.subscribe(user => {
      this.currentUser = of(user);
    });

    this.currentCredential = of(null);
    const retrievedToken = localStorage.getItem('alb-ms-access');

    console.log('Retrieved Token');
    console.log(retrievedToken);
    this.accessToken.next(retrievedToken);

    this.angularFireAuth.onAuthStateChanged((user) => {
      console.log('onAuthStateChanged');
      console.log(user);
      this.currentUser = of(user);
    });

    this.angularFireAuth.getRedirectResult()
      .then(result => {
        if (result.credential) {
          console.log('Got Credential');

          this.currentCredential = of(result.credential);

          // @ts-ignore
          const accessToken = result.credential.accessToken;
          // @ts-ignore
          const refreshToken = result.credential.idToken;

          this.accessToken.next(accessToken);

          localStorage.setItem('alb-ms-access', accessToken);
          localStorage.setItem('alb-ms-refresh', refreshToken);
        } else {
          this.currentCredential = of(null);
        }

        if (result.user) {
          this.currentUser = of(result.user);
        } else {
          this.currentUser = of(null);
        }
        this.redirectResult.next(result);
      })
      .catch(error => {
        console.log(error);
        this.redirectResult.next({error: error.code});
      });
  }

  // TODO: We will need to decide whether we need to signIn, or just reauthenticate.
  microsoftLogin(): void {
    console.log('Doing Login');
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('user.read');
    firebase.auth().signInWithRedirect(provider).then(() => {
      console.log('signInWithRedirect');
    });
  }

  microsoftReLogin(): void {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('user.read');
    firebase.auth().currentUser.reauthenticateWithRedirect(provider);
  }

  logout(): void {
    firebase.auth().signOut().then(() => {
      localStorage.removeItem('alb-ms-access');
      localStorage.removeItem('alb-ms-refresh');
      console.log('logged-out');
    });
  }

  // TODO: We will also need to determine when we need to refresh the accessToken from Microsoft.
  // The Guide is Woeful. https://firebase.google.com/docs/auth/web/microsoft-oauth
  // The only time at which point we can get the accessToken for accessing the Graph API is with getRedirectResult() below.
  // We don't seem to have access to the ExpiryDate of the Token Either

  getRedirectResult(): Observable<UserCredential> {
    return this.redirectResult;
  }

  getCurrentToken(): Observable<string> {
    return this.accessToken;
  }

  getCurrentUser(): Observable<firebase.User> {
    return this.angularFireAuth.authState;
  }
}
