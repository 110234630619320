import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AzureService} from '../azure/azure.service';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {FirebaseLoginService} from '../firebase/firebase-login/firebase-login.service';
import {FirebaseEquipmentService} from '../firebase/firebase-equipment/firebase-equipment.service';
import {MatSort} from '@angular/material/sort';

export class Employee {
  displayName: string;
  loanedItems: number;
  department: string;
  email: string;
  id: string;
}

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})

export class EmployeeListComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<Employee>;
  displayedColumns: string[] = ['displayName', 'email', 'department', 'loanedItems'];
  uid: string;
  currentLoansOnly = true;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private firebaseLogin: FirebaseLoginService,
    private firebaseEquipment: FirebaseEquipmentService,
    private azureService: AzureService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.firebaseLogin.getCurrentToken().subscribe(result => {
      if (result) {
        this.firebaseLogin.getCurrentUser().subscribe(user => {
          console.log('user');
          console.log(user);
        });

        this.azureService.getUsers(result).then(users => {
          console.log(users);
          // "Asset Register Users"
          this.azureService.getGroupMembers(result, '47251364-12bf-4b4f-822b-2c097abd17cf').then(groupUsers => {
            const filteredUsers = groupUsers.filter(gu => gu['@odata.type'] === '#microsoft.graph.user');
            const employees = filteredUsers.map(groupUser => {
              return {
                displayName: groupUser.displayName,
                loanedItems: 0,
                department: users.filter(a => a.id === groupUser.id).map(b => b.department).shift(),
                email: groupUser.mail,
                id: groupUser.id
              } as Employee;
            });
            for (const employee of employees) {
              this.firebaseEquipment.GetActiveLoansForEmployee(employee.id).subscribe(equipment => {
                employee.loanedItems = equipment.length;
              });
            }
            this.dataSource = new MatTableDataSource(employees);
            this.dataSource.filterPredicate = this.customFilterPredicate();
            // TODO: Initial Filter not applying correctly. should only show people with ActiveLoan Items, but not activating.
            this.dataSource.filter = '';
            this.dataSource.sort = this.sort;
          });
        });
      }
    });
  }

  ngAfterViewInit(): void {

  }

  customFilterPredicate(): (data: Employee, filter: string) => boolean {
    return (data: Employee, filter: string): boolean => {
      return ((!filter && data.loanedItems > 0) ||
          (filter &&
          ((data.displayName.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.department.trim().toLowerCase().indexOf(filter) !== -1) ||
          (data.email.trim().toLowerCase().indexOf(filter) !== -1))));
    };
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.currentLoansOnly = filterValue.trim() === '';
    console.log(this.currentLoansOnly);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayEmployee(employee: Employee): void {
    this.router.navigateByUrl('/employee/' + employee.id);
  }
}
