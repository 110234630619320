import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {Equipment} from './Equipment';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import {map, switchMap} from 'rxjs/operators';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';

export interface FilesUploadMetadata {
  uploadProgress$: Observable<number>;
  downloadUrl$: Observable<string>;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseEquipmentService {

  private equipmentCollection: AngularFirestoreCollection<Equipment>;

  constructor(private afs: AngularFirestore, private afStorage: AngularFireStorage) {
    this.equipmentCollection = this.afs.collection<Equipment>('equipment');
  }

  GetActiveLoansForEmployee(userId: string): Observable<Equipment[]> {
    const collection = this.afs.collection<Equipment>('equipment', ref => ref.where('loanedToId', '==', userId).where('loanStatus', '==', 'Active'));
    return collection.snapshotChanges().pipe(map(items => {
      return items.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as Equipment;
      });
    }));
  }

  GetReturnedLoansForEmployee(userId: string): Observable<Equipment[]> {
    const collection = this.afs.collection<Equipment>('equipment', ref => ref.where('loanedToId', '==', userId).where('loanStatus', '==', 'Returned'));
    return collection.snapshotChanges().pipe(map(items => {
      return items.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data(),
        } as Equipment;
      });
    }));
  }

  dataURItoBlob(base64: string): Blob {
    const base64Data = base64.replace('data:image/png;base64,', '');
    const byteString = window.atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], {type: 'image/png'});
  }

  AddPicture(documentReference: DocumentReference, base64: string): FilesUploadMetadata {
    const imageBlob = this.dataURItoBlob(base64);
    const imageFile = new File([imageBlob], documentReference.id, { type: 'image/png' });

    let uploadTask: AngularFireUploadTask;
    const filePath = 'equipment/loanee-signature/' + documentReference.id;
    uploadTask = this.afStorage.upload(filePath, imageFile);
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string,
  ): Observable<string> {
    return from(uploadTask).pipe(
      switchMap((_) => this.afStorage.ref(path).getDownloadURL()),
    );
  }

  AddLoan(item: Equipment): Promise<DocumentReference> {
    delete item.id;
    return this.equipmentCollection.add(item);
  }

  UpdateLoan(item: Equipment): void {
    console.log(item);
    this.equipmentCollection.doc(item.id).update(item);
  }
}
