<h2 mat-dialog-title>Issue Equipment to {{ displayName }}</h2>
<mat-dialog-content [formGroup]="form" *ngIf="form">
  <div fxLayout="column" fxLayoutGap.gt-sm="24px">
    <mat-form-field appearance="fill">
      <mat-label>Loan Date</mat-label>
      <input matInput [matDatepicker]="loanedDate" formControlName="loanedDate">
      <mat-datepicker-toggle matSuffix [for]="loanedDate"></mat-datepicker-toggle>
      <mat-datepicker #loanedDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Serial/Reference</mat-label>
      <input matInput formControlName="reference">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description">
    </mat-form-field>
    Sign Below to Confirm Receipt:
    <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="save()">Issue</button>
</mat-dialog-actions>

