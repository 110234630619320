<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:160px;">Name</th>
    <td mat-cell *matCellDef="let element" style="width:160px;">{{ element.displayName }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:260px;">Email</th>
    <td mat-cell *matCellDef="let element" style="width:260px;">{{ element.email }}</td>
  </ng-container>
  <ng-container matColumnDef="department">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:160px;">Department</th>
    <td mat-cell *matCellDef="let element" style="width:160px;">{{ element.department }}</td>
  </ng-container>
  <ng-container matColumnDef="loanedItems">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:60px;">Active Loans</th>
    <td mat-cell *matCellDef="let element" style="width:60px;">{{ element.loanedItems }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="displayEmployee(row)"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
  </tr>
</table>
