import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FirebaseLoginService} from '../firebase/firebase-login/firebase-login.service';
import {AzureService} from '../azure/azure.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-welcome-component',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(private firebaseLogin: FirebaseLoginService, private azureService: AzureService, private sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit(): void {
    // TODO: We need to merge this into a service
    this.firebaseLogin.getCurrentToken().subscribe(token => {
      if (token) {
        console.log('token');
        console.log(token);

        this.azureService.getMe(token).then(me => {
          // "Asset Register Management"
          this.azureService.getGroupMembers(token, 'ed028f1f-168b-4d4d-8344-5d4e6d7d5b6e').then(management => {
            const manager = (management.find(m => m.id === me.id));
            if (manager) {
              this.router.navigateByUrl('/employee');
            }
          });
        }).catch(error => {
          console.log('Token Invalid');
          console.log(error);
        });
      }
    });
  }

}
