import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ViewChild } from '@angular/core';
import { SignaturePad } from 'ngx-signaturepad';

@Component({
  selector: 'app-issue-equipment',
  templateUrl: './issue-equipment.component.html',
  styleUrls: ['./issue-equipment.component.css']
})
export class IssueEquipmentComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300
  };

  displayName = '';
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<IssueEquipmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.displayName = this.data.displayName;
    this.form = this.fb.group({
      reference: '',
      description: '',
      loanedDate: new Date()
    });
  }

  ngAfterViewInit(): void {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete(): void {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
  }

  drawStart(): void {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({equipment: this.form.value, signature: this.signaturePad.toDataURL() });
  }
}
