<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .example-spacer {
    flex: 1 1 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .toolbar button {
    margin: 8px;
  }

  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .terminal pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container > *:not(.circle-link),
    .card.highlight-card span {
      margin-left: 72px;
    }
  }

</style>
<!-- Toolbar -->
<p>
  <mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="menu" [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <span>ACUTEC Asset Register</span>
    <span class="example-spacer"></span>
    <button *ngIf="isLoggedIn" mat-fab color="accent" aria-label="login" (click)="logOut()"
            [style.background-image]="pictureStyle" style="background-size: contain;">
    </button>
    <button *ngIf="!isLoggedIn" mat-fab color="accent" aria-label="logout" (click)="logIn()">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar>
</p>

<mat-menu #menu="matMenu">
  <button *ngIf="isLoggedIn && isManager" mat-menu-item (click)="goToEmployees()">
    <mat-icon>group</mat-icon>
    <span>Employees</span>
  </button>
  <button *ngIf="!isLoggedIn" mat-menu-item (click)="logIn()">
    <mat-icon>account_circle</mat-icon>
    <span>Login</span>
  </button>
  <button *ngIf="isLoggedIn" mat-menu-item (click)="logOut()">
    <mat-icon>cancel</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<div class="content" role="main">
  <div *ngIf="!isManager && isLoggedIn">
    <mat-card>
      <mat-card-title>Permissions</mat-card-title>
      <mat-card-content>
        <p>
          Welcome to the ACUTEC Asset Management System. It looks like you do not have permission to be able to view employees or manage their assets and loans.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="logOut()">Logout</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div *ngIf="!isLoggedIn">
    <mat-card>
      <mat-card-title>Not Logged In</mat-card-title>
      <mat-card-content>
        <p>
          Welcome to the ACUTEC Asset Management System. You don't appear to be logged in and so you are unable to view any employees, assets and loans in the system.
          Please use the pink login button in the header, or the login button below to authentication using your Microsoft 365 credentials.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="logIn()">Login</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <router-outlet></router-outlet>
  <!-- Footer -->
  <footer>

  </footer>
</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


